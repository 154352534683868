import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`DB Step Up Jumps 5×2/Leg`}</p>
    <p>{`Wt’d Shoulder Bridge 5×6 (Shoulders on bench, weight across hips)`}</p>
    <p>{`then,`}</p>
    <p>{`Death by Back Squat (185/125)(RX+ 225/155)`}</p>
    <p>{`1 Squat Minute 1:00, 2 Squats Minute 2:00, 3 Squats Minute 3:00 etc
until failure`}</p>
    <p>{`Rest 2:00 then,`}</p>
    <p>{`Death by Assault Bike`}</p>
    <p>{`2 Calories Minute 1:00, 4 Calories Minute 2:00, 6 Calories Minute 3:00
etc until failure`}</p>
    <p>{`*`}{`Must reset bike each round.`}</p>
    <p>{`Score = total completed rounds of Back Squat and Calorie Assault Bike
combined.`}</p>
    <p>{`*`}{`*`}{`compare score to 3/31/21.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      